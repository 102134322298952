@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
    font-family: "Exo 2", sans-serif;
  /* font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title-font {
  font-family: "Abril Fatface", serif;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.scrolling-touch { -webkit-overflow-scrolling: touch; }
.scrolling-auto { -webkit-overflow-scrolling: auto; }


button:disabled{
  cursor: wait !important;
  background-color: var(--borderColor) !important;
  opacity: 0.4;
  color: var(--color-black) !important;
  outline: none;
  border: 2px solid var(--borderColor) !important;
  padding: 10px;
  
}
button:disabled:hover {
    opacity: 1;
  cursor: wait !important;
  background-color: var(--bgColor) !important;
  color: var(--borderColor) !important;
  border: 2px solid var(--color-black);
}

#landing-quran-canvas {
  height: 700px;
  /* min-height: 700px; */
}

canvas {
  /* height: 900px; */
  min-height: 900px;
}

.outline-text {
  -webkit-text-stroke: 2px white; /* Adjust the color and width of the outline as needed */
}

.landing-bg {
  background-image: url("./assets/images/landing-bg.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain; /* Optional: ensure the background covers the entire div */
   opacity: .2;
}

/* styles.css or the relevant CSS file */
.translucent-bg {
  backdrop-filter: blur(3px); /* Apply blur effect */
  background-color: rgba(255, 255, 255, 0.8); /* White background with 80% opacity */
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease; /* Smooth transition for the effects */
}

.dark .translucent-bg {
  background-color: rgba(18, 18, 18, 0.8); /* Dark mode background with 80% opacity */
}

.subscribe-section {
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/022/925/493/non_2x/white-abstract-geometric-shapes-background-ideal-for-poster-cover-branding-wallpaper-banner-website-presentation-modern-geometry-in-minimal-concept-classic-and-clean-3d-rendering-free-photo.jpg"); */
  background-image: url("../src/assets/images/subscribe-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.discover-section {
  /* background-image: url("https://t3.ftcdn.net/jpg/04/03/02/92/360_F_403029269_KCrGHt5AdtV7GSD2KeP8Wk2PYIbVKlNU.jpg"); */
  background-image: url("../src/assets/images/discover-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.translation-section {
  /* background-image: url("https://t3.ftcdn.net/jpg/04/03/02/92/360_F_403029269_KCrGHt5AdtV7GSD2KeP8Wk2PYIbVKlNU.jpg"); */
  /* background-image: url("../src/assets/images/white-curve-bg.jpeg"); */
   /* background-image: url("../src/assets/images/discover-bg.jpg"); */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  .landing-bg {
    background-size: cover; /* Change background-size to cover for mobile screens */
  }
}